import { DATE_TIME_FORMAT } from "@shared/constants/index";
import PdfDocumentIcon from "@shared/svg/pdf-document.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import moment from "moment-timezone";
import { X } from "phosphor-react";
import React, { useEffect, useState } from "react";

type AttachmentPropsType = {
  file: File;
  onRemove?: () => void;
};

const Attachment = ({ file, onRemove = () => {} }: AttachmentPropsType) => {
  const { name, url, type, createdAt = "" } = file;
  const reader = new FileReader();
  const isPdf = type.includes("pdf");

  const [imgSrc, setImgSrc] = useState<string>("");

  const updateImgSrc = () => {
    setImgSrc(reader.result as string);
  };

  useEffect(() => {
    if (url) {
      setImgSrc(url);
      return;
    }
    reader.readAsDataURL(file);
    reader.addEventListener("load", updateImgSrc);

    return () => {
      reader.removeEventListener("load", updateImgSrc);
    };
  }, [file]);

  return (
    <>
      <div className="flex flex-col w-44xl h-36 border border-solid border-primary rounded-lg">
        <div className="flex relative w-full h-24 min-h-24 rounded-t-lg">
          {isPdf ? (
            <PdfDocumentIcon className="m-auto" />
          ) : (
            <>
              <img
                src={imgSrc}
                className="w-full max-w-full max-h-24 rounded-t-lg object-cover"
              />
              <div className="absolute top-0 w-full h-full bg-secondary/40 rounded-t-lg" />
            </>
          )}
          <div
            className={`absolute top-md right-lg flex w-xl h-xl cursor-pointer bg-primary rounded-md ${
              isPdf ? "border border-solid border-primary" : ""
            }`}
            onClick={onRemove}
          >
            <X size={12} className="text-secondary m-auto" />
          </div>
        </div>
        <div className="flex flex-col w-full p-xs bg-accent rounded-b-lg">
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            className="truncate no-styles"
          >
            {name}
          </BodyText>
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="no-styles"
          >
            {moment(createdAt || new Date()).format(DATE_TIME_FORMAT)}
          </BodyText>
        </div>
      </div>
    </>
  );
};

export default Attachment;
