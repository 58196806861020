import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { ProcedureInstanceListItem as ProcedureInstanceListItemCard } from "@shared/ui/ListItemCards";
import React from "react";
import {} from "phosphor-react";
import { useIntl } from "react-intl";

import { Download } from "~/icons";

const ProcedureListItem = ({ item }) => {
  const { messages } = useIntl();

  return (
    <ProcedureInstanceListItemCard
      item={item}
      hideProcedureId
      hideCreatedAt={false}
      labelClassName="finalized"
      labelComponent={
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          className="truncate inline-block px-2xs py-3xs rounded-md bg-success-lightest"
          color="text-success"
        >
          {messages?.tickets?.procedureStates[item.state]}
        </BodyText>
      }
      actionButton={
        <div className="flex items-center justify-center u-padding-1 u-border-solid u-border-width-1 u-border-color-gray-v2-10 u-rounded-large">
          <Download
            onClick={() => window.open(item?.pdfUrl, "_blank")}
            className="u-cursor-pointer"
          />
        </div>
      }
    />
  );
};

export default ProcedureListItem;
