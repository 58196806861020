import colors from "./colorPrimitives";

const textColor = {
  primary: colors.grey[500],
  secondary: colors.grey[400],
  tertiary: colors.grey[300],
  disabled: colors.grey[200],
  brand: {
    lightest: colors.primary[100],
    light: colors.primary[200],
    DEFAULT: colors.primary[400],
  },
  warning: colors.orange[400],
  danger: colors.red[400],
  success: colors.green[400],
  pink: colors.pink[400],
  violet: colors.violet[400],
  brown: colors.brown[400],
  purple: colors.purple[400],
  mintBlue: colors.mintBlue[400],
  inverse: colors.base.white,
};

export default textColor;
