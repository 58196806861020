import colors from "./colorPrimitives";

const backgroundColor = {
  primary: colors.base.white,
  secondary: colors.grey[500],
  tertiary: colors.grey[100],
  accent: colors.grey[50],
  dark: colors.grey[300],
  brand: {
    lightest: colors.primary[100],
    light: colors.primary[200],
    medium: colors.primary[300],
    dark: colors.primary[400],
  },
  yellow: {
    lightest: colors.yellow[200],
    light: colors.yellow[300],
    DEFAULT: colors.yellow[400],
  },
  warning: {
    lightest: colors.orange[200],
    light: colors.orange[300],
    DEFAULT: colors.orange[400],
  },
  danger: {
    lightest: colors.red[100],
    light: colors.red[200],
    DEFAULT: colors.red[400],
  },
  success: {
    lightest: colors.green[100],
    light: colors.green[300],
    DEFAULT: colors.green[400],
  },
  pink: {
    lightest: colors.pink[200],
    light: colors.pink[300],
    DEFAULT: colors.pink[400],
  },
  violet: {
    lightest: colors.violet[200],
    light: colors.violet[300],
    DEFAULT: colors.violet[400],
  },
  purple: {
    lightest: colors.purple[200],
    light: colors.purple[300],
    DEFAULT: colors.purple[400],
  },
  mint: {
    lightest: colors.mintBlue[200],
    light: colors.mintBlue[300],
    DEFAULT: colors.mintBlue[400],
  },
  brown: {
    lightest: colors.brown[200],
    light: colors.brown[300],
    DEFAULT: colors.brown[400],
  },
  disabled: colors.grey[200],
  light: {
    "10": colors.lightTranparent[10],
    "25": colors.lightTranparent[25],
    "50": colors.lightTranparent[50],
    "75": colors.lightTranparent[75],
  },
};

export default backgroundColor;
