import { FILE_SIZE } from "@shared/constants/fileSizes";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { Checkbox, InputLabel } from "@shared/ui/Inputs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import isHotkey from "is-hotkey";
import {
  ListBullets,
  ListNumbers,
  TextBolder,
  TextItalic,
  TextUnderline,
  TextStrikethrough,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
  Paperclip,
  Check,
  XCircle,
} from "phosphor-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import {
  Editable,
  withReact,
  Slate,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
} from "slate-react";

import Attachment from "./components/Attachment";
import BlockButton, { BLOCK_BUTTON_FORMAT } from "./components/BlockButton";
import ElementRenderer, { ELEMENT_TYPES } from "./components/ElementRenderer";
import LeafButton, {
  LEAF_BUTTON_FORMAT,
  LeafButtonFormatType,
} from "./components/LeafButton";
import LeafRenderer from "./components/LeafRenderer";
import { toggleMark } from "./utils";

export { serialize } from "./utils";

const HOTKEYS = {
  "mod+b": LEAF_BUTTON_FORMAT.BOLD,
  "mod+i": LEAF_BUTTON_FORMAT.ITALIC,
  "mod+u": LEAF_BUTTON_FORMAT.UNDERLINE,
  "mod+s": LEAF_BUTTON_FORMAT.STRIKE_THROUGH,
} as const;

type TextEditorPropsType = {
  label?: string;
  keyId: string;
  uppercaseLabel?: boolean;
  readOnly?: boolean;
  content?: string;
  onUpdate?: (value: Descendant[]) => void;
  placeholder?: string;
  autoFocus?: boolean;
  toolbarTopBorder?: boolean;
  className?: string;
  mainEditorClassName?: string;
  showToolbar?: boolean;
  showAttachmentButton?: boolean;
  showActionButtons?: boolean;
  showCheckbox?: boolean;
  handleClose?: () => void;
  handleSave?: (files: File[], isChecked: boolean) => void;
  isSaving?: boolean;
  defaultAttachments?: File[];
  setIsScrolling?: (scrolling: boolean) => void;
  defaultCheckboxValue?: boolean;
  checkboxLabel?: string;
  disabled?: boolean;
};

const TextEditor = ({
  label = "",
  keyId,
  uppercaseLabel = false,
  readOnly = false,
  content = "",
  onUpdate = null,
  placeholder = "",
  autoFocus = false,
  toolbarTopBorder = false,
  className = "",
  mainEditorClassName = "",
  showToolbar = false,
  showAttachmentButton = false,
  showActionButtons = false,
  showCheckbox = true,
  handleClose = () => {},
  handleSave = () => {},
  isSaving = false,
  defaultAttachments = [],
  setIsScrolling = () => {},
  defaultCheckboxValue = false,
  checkboxLabel = "",
  disabled = false,
  ...props
}: TextEditorPropsType & React.ComponentPropsWithoutRef<"div">) => {
  // Editor
  const editor: ReactEditor = useMemo(
    () => withHistory(withReact(createEditor())),
    [],
  );

  const isContentEmpty = (value: string) => {
    try {
      const parsedValue = JSON.parse(value);
      if (
        parsedValue?.length === 1 &&
        parsedValue[0]?.type === ELEMENT_TYPES.PARAGRAPH &&
        parsedValue[0]?.children?.[0]?.text === ""
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return true;
    }
  };

  const [files, setFiles] = useState<File[]>(defaultAttachments);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(!isContentEmpty(content));
  const [isCheckboxChecked, setIsCheckboxChecked] =
    useState<boolean>(defaultCheckboxValue);

  const { messages, formatMessage } = useIntl();

  const editableRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<string | Descendant[]>(content);

  // Element Renderer
  const renderElement = useCallback(
    (props: RenderElementProps) => <ElementRenderer {...props} />,
    [],
  );

  // Leaf Renderer
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <LeafRenderer {...props} />,
    [],
  );

  useEffect(() => {
    if (editableRef.current && editableRef.current.offsetHeight > 150) {
      setIsScrolling(true);
    }
  }, [setIsScrolling]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles?.length) {
        let message = messages?.common?.fileNotSupported;
        if (rejectedFiles[0].errors[0].code === "file-too-large") {
          message = formatMessage(
            {
              id: "messages.procedures.fieldAttachmentLargeFile",
              defaultMessage: messages.procedures.fieldAttachmentLargeFile,
            },
            {
              size: FILE_SIZE.MB_10,
            },
          );
        }
        toast(<Toast type={TOAST_TYPES.ERROR} message={message} />, {
          closeButton: false,
        });
        return;
      }
      setFiles((prev) => {
        const newState = [...prev, ...acceptedFiles];
        return newState;
      });
    },
    [],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: false,
    multiple: true,
    maxSize: FILE_SIZE.MB_10 * 1000000,
    accept: [".png", ".jpeg", ".jpg", ".pdf"],
  });

  // Prepare Editor Formatted Data
  const preparedContent: Descendant[] = useMemo(() => {
    let parsedData = [
      {
        type: ELEMENT_TYPES.PARAGRAPH,
        children: [{ text: "" }],
      },
    ];
    if (!content) {
      return parsedData;
    }
    try {
      if (content.length && content[0] !== "[")
        throw new Error("Invalid Slate Value");

      const parsedString = JSON.parse(content);
      parsedData = parsedString === "" ? parsedData : parsedString;
    } catch (e) {
      parsedData = [
        {
          type: ELEMENT_TYPES.PARAGRAPH,
          children: [{ text: content }],
        },
      ];
    }
    return parsedData;
  }, [content]);

  const checkHasValue = useCallback(() => {
    if (!contentRef.current) return;
    const value = contentRef.current ?? "";

    if (
      !files.length &&
      value?.length === 1 &&
      value[0]?.type === ELEMENT_TYPES.PARAGRAPH &&
      value[0]?.children?.[0]?.text === ""
    ) {
      setHasValue(false);
      return;
    }
    setHasValue(true);
  }, [files]);

  const checkDisableSaveButton = useCallback(() => {
    if (!showActionButtons || !contentRef.current) return;
    const value = contentRef.current ?? "";

    if (
      (!files.length &&
        value?.length === 1 &&
        value[0]?.type === ELEMENT_TYPES.PARAGRAPH &&
        value[0]?.children?.[0]?.text === "") ||
      (!value && !files.length && showAttachmentButton)
    ) {
      setDisableSave(true);
      return;
    }
    setDisableSave(false);
  }, [showActionButtons, files, showAttachmentButton, setDisableSave]);

  useEffect(() => {
    checkDisableSaveButton();
  }, [files, checkDisableSaveButton]);

  const onChange = (value: Descendant[]) => {
    if (readOnly) return;
    contentRef.current = value;
    checkDisableSaveButton();
    checkHasValue();
    onUpdate(value);
  };

  const handleRemoveAttachment = (indexToRemove: number) => {
    setFiles((prev) => prev.filter((item, index) => index !== indexToRemove));
  };

  return (
    <div
      className={`relative flex flex-col gap-sm ${
        disabled ? "opacity-50" : ""
      }`}
    >
      {label && (
        <div className="w-fit">
          <InputLabel
            label={label}
            keyId={keyId}
            uppercaseLabel={uppercaseLabel}
          />
        </div>
      )}

      <Slate editor={editor} value={preparedContent} onChange={onChange}>
        <div
          className={`border border-solid rounded-lg has-[:focus]:border-brand has-[:focus]:bg-primary ${
            readOnly && !showToolbar
              ? "border-0 bg-transparent"
              : hasValue
              ? "border-primary bg-primary"
              : "border-transparent bg-accent"
          } ${className || ""}`}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (readOnly && onUpdate) {
              onUpdate(null);
            }
          }}
          {...props}
        >
          {(!readOnly || showToolbar) && (
            <div
              className={`flex items-center px-lg py-md ${
                toolbarTopBorder ? "border-t border-b-0" : "border-b border-t-0"
              } border-x-0 border-solid border-primary gap-md`}
            >
              {showAttachmentButton && (
                <div {...getRootProps()} className="flex">
                  <input {...getInputProps()} />
                  <Paperclip
                    size={20}
                    className="text-secondary cursor-pointer"
                  />
                </div>
              )}
              <BlockButton
                format={BLOCK_BUTTON_FORMAT.NUMBERED_LIST}
                icon={<ListNumbers size={20} />}
                readOnly={readOnly}
              />
              <BlockButton
                format={BLOCK_BUTTON_FORMAT.BULLETED_LIST}
                icon={<ListBullets size={20} />}
                readOnly={readOnly}
              />
              <BlockButton
                format={BLOCK_BUTTON_FORMAT.LEFT}
                icon={<TextAlignLeft size={20} />}
                readOnly={readOnly}
              />
              <BlockButton
                format={BLOCK_BUTTON_FORMAT.CENTER}
                icon={<TextAlignCenter size={20} />}
                readOnly={readOnly}
              />
              <BlockButton
                format={BLOCK_BUTTON_FORMAT.RIGHT}
                icon={<TextAlignRight size={20} />}
                readOnly={readOnly}
              />
              <LeafButton
                format={LEAF_BUTTON_FORMAT.STRIKE_THROUGH}
                icon={<TextStrikethrough size={20} />}
                readOnly={readOnly}
              />
              <LeafButton
                format={LEAF_BUTTON_FORMAT.ITALIC}
                icon={<TextItalic size={20} />}
                readOnly={readOnly}
              />
              <LeafButton
                format={LEAF_BUTTON_FORMAT.BOLD}
                icon={<TextBolder size={20} weight="bold" />}
                readOnly={readOnly}
              />
              <LeafButton
                format={LEAF_BUTTON_FORMAT.UNDERLINE}
                icon={<TextUnderline size={20} />}
                readOnly={readOnly}
              />
              {showActionButtons && (
                <div className="flex items-center ml-auto">
                  {showCheckbox && (
                    <div
                      className="flex items-center gap-sm mr-lg"
                      onClick={() => setIsCheckboxChecked((prev) => !prev)}
                    >
                      <Checkbox checked={isCheckboxChecked} />
                      <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                        {checkboxLabel}
                      </BodyText>
                    </div>
                  )}
                  <XCircle
                    size={24}
                    className={
                      isSaving ? "text-disabled" : "cursor-pointer text-brand"
                    }
                    onClick={() => !isSaving && handleClose()}
                  />
                  <div
                    className={`inline-flex items-center justify-center w-xl h-xl ml-md text-inverse rounded-full ${
                      isSaving || disableSave
                        ? "cursor-default bg-disabled"
                        : "cursor-pointer bg-brand"
                    }`}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                    ) => {
                      e.stopPropagation();
                      !isSaving &&
                        !disableSave &&
                        handleSave(files, isCheckboxChecked);
                    }}
                  >
                    <Check size={16} />
                  </div>
                </div>
              )}
            </div>
          )}
          {files.length > 0 && (
            <div className="flex flex-wrap w-full m-lg gap-md">
              {files.map((file, index) => {
                return (
                  <Attachment
                    key={index}
                    onRemove={() => handleRemoveAttachment(index)}
                    file={file}
                  />
                );
              })}
            </div>
          )}
          <div
            className={`max-h-40 overflow-y-auto text-xs font-medium ${
              readOnly && !showToolbar ? "p-0" : "p-lg"
            } ${mainEditorClassName}`}
          >
            <div ref={editableRef}>
              <Editable
                id={keyId}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={
                  placeholder || messages?.common?.textEditorPlaceholder
                }
                autoFocus={autoFocus}
                spellCheck
                readOnly={readOnly}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  for (const hotkey in HOTKEYS) {
                    if (isHotkey(hotkey, event)) {
                      event.preventDefault();
                      const mark: LeafButtonFormatType = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Slate>
    </div>
  );
};

export default TextEditor;
