import React from "react";

type EditorButtonPropsType = {
  active: boolean;
  onMouseDown: React.MouseEventHandler<HTMLSpanElement>;
  children: React.ReactNode;
};

const EditorButton = ({
  active,
  onMouseDown,
  children,
}: EditorButtonPropsType) => (
  <span
    className={`flex items-center justify-center w-xl h-xl cursor-pointer ${
      active ? "text-brand" : "text-secondary"
    }`}
    onMouseDown={onMouseDown}
  >
    {children}
  </span>
);

export default EditorButton;
