import ReloadPageImage from "@shared/svg/reload-page.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import Toast from "@shared/ui/Toast";
import { getAllRoles } from "@shared/utils/getAllRoles";
import PropTypes from "prop-types";
import React from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  globalLoginPopupForGMU,
  globalAppVersionAtom,
  globalErrorAtom,
  MaintenanceAtom,
} from "~/atoms/_global";
import { Authenticate, useAuth } from "~/components/general";
import { FlashMessageWrapper } from "~/containers/_flashMessageWrapper";
import { Layout } from "~/layout";
import ReloadPrompt from "~/ReloadPrompt";
import "react-calendar/dist/Calendar.css";

const ROLES = getAllRoles();

const App = ({ match, location }) => {
  const history = useHistory();
  const { messages } = useIntl();
  const { state } = location;
  const { chat, user, refetch } = useAuth();

  const [gmuModalCoil, setGMUModalCoil] = useRecoilState(
    globalLoginPopupForGMU,
  );
  const { isErrorAlertOpen } = useRecoilValue(globalErrorAtom);
  const flashMessage = useRecoilValue(globalAppVersionAtom);
  const { isMaintenanceOn } = useRecoilValue(MaintenanceAtom);

  React.useEffect(() => {
    let tokenInterval;

    if (chat && user) {
      const SECONDS = 60;
      const MILLISECONDS = 1000;
      const timeOffset = SECONDS * MILLISECONDS;
      const { ttl, timestamp } = chat?.parseToken(user?.chatToken) || {};
      const tokenTime = new Date(timestamp * 1000 + ttl * 60 * 1000).getTime();

      tokenInterval = setInterval(() => {
        const now = new Date().getTime() + timeOffset;
        if (now > tokenTime) {
          refetch();
        }
      }, 10 * MILLISECONDS);
    }

    return () => clearInterval(tokenInterval);
  }, [user, chat]);

  React.useEffect(() => {
    if (
      user?.role === ROLES.GMU.VALUE &&
      !location.pathname.includes("/machines/view/")
    ) {
      setGMUModalCoil({ ...gmuModalCoil, showPopup: true });
    }
  }, [user?.role, location?.pathname]);

  React.useEffect(() => {
    if (state?.welcome) {
      toast(<Toast message={messages?.login?.welcome + "!"} />, {
        closeButton: false,
      });
      history.replace({ ...location, state: undefined });
    }
  }, []);

  return (
    <div className="main">
      <Authenticate>
        {isMaintenanceOn && (
          <AlertBox
            isOpen={isMaintenanceOn}
            overlay
            image={<ReloadPageImage width="130" height="130" />}
            title={messages["common"]["maintenanceMessage"]}
          />
        )}
        {flashMessage?.showFlashMessage &&
          createPortal(<FlashMessageWrapper />, document.body)}
        <Layout match={match} location={location} />
        {isErrorAlertOpen && (
          <AlertBox
            isOpen={isErrorAlertOpen}
            portalSelector=".error-alert-box-modal-portal"
            overlay
            image={<ReloadPageImage width="130" height="130" />}
            title={messages?.common?.reloadAppMessage}
            acceptButtonText={messages?.common?.refresh}
            onAccept={() => {
              window.location.reload();
            }}
          />
        )}
      </Authenticate>
      <ReloadPrompt />
    </div>
  );
};
App.propTypes = {
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default App;
