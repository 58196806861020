import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import moment from "moment-timezone";
import { useIntl } from "react-intl";

type ProcedureInstanceListItemPropsType = {
  item: any;
  hideProcedureId?: boolean;
  hideCreatedAt?: boolean;
  labelClassName: string;
  labelComponent: React.ReactNode;
  actionButton: React.ReactNode;
};

const ProcedureInstanceListItem = ({
  item,
  hideProcedureId = false,
  hideCreatedAt = true,
  labelClassName,
  labelComponent,
  actionButton,
}: ProcedureInstanceListItemPropsType) => {
  const { messages } = useIntl();

  return (
    <div className="h-5xl flex items-center justify-between px-md mb-lg border border-solid border-primary bg-primary rounded-lg gap-md">
      <div className="w-2/5 flex flex-col sm:flex-row">
        <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
          {item.name}
        </BodyText>

        {!hideCreatedAt && (
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            className="block sm:hidden truncate mt-sm"
          >
            <span className="text-secondary">
              {messages?.tickets?.createdText}:{" "}
            </span>
            {moment(item.createdAt).format("YYYY-MM-DD")}
          </BodyText>
        )}
      </div>

      {!hideProcedureId && (
        <div className="w-1/6">
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
            <span className="text-secondary">{messages?.common?.id}: </span>
            {item?.procedureId}
          </BodyText>
        </div>
      )}

      {!hideCreatedAt && (
        <div className="w-1/6 hidden sm:block">
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="truncate">
            <span className="text-secondary">
              {messages?.tickets?.createdText}:{" "}
            </span>
            {moment(item.createdAt).format("YYYY-MM-DD")}
          </BodyText>
        </div>
      )}

      <div className={`sm:w-1/6 flex items-center ${labelClassName}`}>
        {labelComponent}
      </div>

      {actionButton}
    </div>
  );
};

export default ProcedureInstanceListItem;
