import { ProductionLineListItem as ProductionLineCard } from "@shared/ui/ListItemCards";
import React from "react";
import { Link } from "react-router-dom";

const ProductionLineListItem = ({
  intl,
  productionLine,
  variant = "small",
  inline,
}) => {
  return (
    <Link
      to={`/${intl?.locale}/app/assets/production-lines/${productionLine?._id}`}
    >
      <ProductionLineCard
        productionLine={productionLine}
        hideFacility
        showDeleteButton={false}
        className="max-md:flex-col max-md:items-start max-md:gap-xs"
      />
    </Link>
  );
};

export default ProductionLineListItem;
