import { TEXT_ALIGN_TYPES, LIST_TYPES } from "@shared/constants/index";
import { Editor, Transforms, Element as SlateElement, Node } from "slate";

import { BlockButtonFormatType } from "./components/BlockButton";
import { ELEMENT_TYPES } from "./components/ElementRenderer";
import { LeafButtonFormatType } from "./components/LeafButton";

// Block Element Toggler
export const toggleBlock = (editor: Editor, format: BlockButtonFormatType) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? BLOCK_TYPES.ALIGN : BLOCK_TYPES.TYPE,
  );
  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive
        ? ELEMENT_TYPES.PARAGRAPH
        : isList
        ? ELEMENT_TYPES.LIST_ITEM
        : format,
    };
  }
  Transforms.setNodes(editor, newProperties);
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

// Leaf Toggler
export const toggleMark = (editor: Editor, format: LeafButtonFormatType) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// Is Block Active
export const BLOCK_TYPES = {
  ALIGN: "align",
  TYPE: "type",
} as const;
type BlockType = (typeof BLOCK_TYPES)[keyof typeof BLOCK_TYPES];

export const isBlockActive = (
  editor: Editor,
  format: BlockButtonFormatType,
  blockType: BlockType = BLOCK_TYPES.TYPE,
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    }),
  );

  return !!match;
};

// Is Leaf Active
export const isMarkActive = (editor: Editor, format: LeafButtonFormatType) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const serialize = (nodes: string) => {
  if (!nodes) return null;
  try {
    const parsedNodes = JSON.parse(nodes);
    return parsedNodes
      .map((n) => {
        return Node.string(n);
      })
      .join(" ");
  } catch (error) {
    return nodes;
  }
};
