export const CheckboxEmpty = ({ strokeColor, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...rest}
  >
    <g clipPath="url(#checkbox-empty)">
      <path
        stroke={strokeColor}
        strokeWidth="1.5"
        d="M3.75 7A3.25 3.25 0 0 1 7 3.75h10A3.25 3.25 0 0 1 20.25 7v10A3.25 3.25 0 0 1 17 20.25H7A3.25 3.25 0 0 1 3.75 17V7Z"
      />
    </g>
    <defs>
      <clipPath id="checkbox-empty">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const CheckboxFilled = ({ fillColor, strokeColor, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...rest}
  >
    <g clipPath="url(#checkbox-filled-1)">
      <path
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="1.8"
        d="M3.9 7A3.1 3.1 0 0 1 7 3.9h10A3.1 3.1 0 0 1 20.1 7v10a3.1 3.1 0 0 1-3.1 3.1H7A3.1 3.1 0 0 1 3.9 17V7Z"
      />
      <g clipPath="url(#checkbox-filled-2)">
        <path
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="m16.125 9.375-5.25 5.25L8.25 12"
        />
      </g>
    </g>
    <defs>
      <clipPath id="checkbox-filled-1">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="checkbox-filled-2">
        <path fill="#fff" d="M6 6h12v12H6z" />
      </clipPath>
    </defs>
  </svg>
);
