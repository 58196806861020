import { CheckboxEmpty, CheckboxFilled } from "@shared/ui/Inputs";

import backgroundColor from "$/settings/tailwind/backgroundColor";
import textColor from "$/settings/tailwind/textColor";

export const CHECKBOX_SIZES = {
  NORMAL: "normal",
  SMALL: "small",
} as const;

type CheckboxSizeType = (typeof CHECKBOX_SIZES)[keyof typeof CHECKBOX_SIZES];

const SIZE_STYLES: Record<CheckboxSizeType, string> = {
  [CHECKBOX_SIZES.NORMAL]: "h-2xl w-2xl",
  [CHECKBOX_SIZES.SMALL]: "h-lg w-lg",
};

type CheckboxPropsType = {
  size?: CheckboxSizeType;
  checked: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const Checkbox = ({
  size = CHECKBOX_SIZES.SMALL,
  checked,
  onClick = () => {},
}: CheckboxPropsType) => (
  <div
    className="flex items-center justify-center cursor-pointer"
    onClick={onClick}
  >
    {checked ? (
      <CheckboxFilled
        className={`${SIZE_STYLES[size]}`}
        fillColor={backgroundColor.secondary}
        strokeColor={textColor.inverse}
      />
    ) : (
      <CheckboxEmpty
        className={`${SIZE_STYLES[size]}`}
        strokeColor={textColor.disabled}
      />
    )}
  </div>
);

export default Checkbox;
