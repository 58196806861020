export const trimText = (text: string, maxLength: number) => {
  if (!text) return null;

  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const isTextEllipsized = (element: any) => {
  if (!element) {
    return false;
  }

  return (
    element.offsetWidth < element.scrollWidth ||
    element.offsetHeight < element.scrollHeight
  );
};
