import React from "react";
import { useSlate } from "slate-react";

import EditorButton from "./EditorButton";
import { isMarkActive, toggleMark } from "../utils";

export const LEAF_BUTTON_FORMAT = {
  STRIKE_THROUGH: "strikethrough",
  ITALIC: "italic",
  BOLD: "bold",
  UNDERLINE: "underline",
} as const;

export type LeafButtonFormatType =
  (typeof LEAF_BUTTON_FORMAT)[keyof typeof LEAF_BUTTON_FORMAT];

type LeafButtonPropsType = {
  format: LeafButtonFormatType;
  icon: React.JSX.Element;
  readOnly: boolean;
};

const LeafButton = ({ format, icon, readOnly }: LeafButtonPropsType) => {
  const editor = useSlate();

  return (
    <EditorButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (readOnly) return;
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </EditorButton>
  );
};

export default LeafButton;
