const colors = {
  grey: {
    "50": "#F3F4F6",
    "100": "#E8EAED",
    "200": "#D1D5DB",
    "300": "#A2AAB8",
    "400": "#748094",
    "500": "#172B4D",
  },
  primary: {
    "100": "#E6E8FE",
    "200": "#CDD1FE",
    "300": "#6974FB",
    "400": "#0517F8",
  },
  red: {
    "100": "#FDE5F0",
    "200": "#FCCCE0",
    "300": "#F780B2",
    "400": "#EE0064",
  },
  green: {
    "100": "#E5F7F5",
    "200": "#CCEFEB",
    "300": "#66D0C3",
    "400": "#00B09B",
  },
  orange: {
    "200": "#FFF7F0",
    "300": "#FFE4CC",
    "400": "#FF7A00",
  },
  yellow: {
    "200": "#FEFBF1",
    "300": "#FEF6CC",
    "400": "#ECB800",
  },
  mintBlue: {
    "200": "#F0FAFB",
    "300": "#CCEDF2",
    "400": "#00A5BC",
  },
  purple: {
    "200": "#F6F7FF",
    "300": "#E1E3FE",
    "400": "#6974FB",
  },
  pink: {
    "200": "#FFF2FF",
    "300": "#FFDBFE",
    "400": "#FF82FA",
  },
  violet: {
    "200": "#F4EBFF",
    "300": "#DBC5F7",
    "400": "#8940E5",
  },
  brown: {
    "200": "#F5EDF0",
    "300": "#E2C9D1",
    "400": "#9E4B65",
  },
  base: {
    white: "#FFFFFF",
    black: "#000000",
  },
  lightTranparent: {
    "10": "rgba(255, 255, 255, 0.1)",
    "25": "rgba(255, 255, 255, 0.25)",
    "50": "rgba(255, 255, 255, 0.5)",
    "75": "rgba(255, 255, 255, 0.75)",
  },
};

export default colors;
