import { PartListItem } from "@shared/ui/ListItemCards";
import React from "react";

import { getPartThumbnail } from "~/utils";

const AddedPartToWorkOrder = ({
  part,
  onDeleteClick,
  machine,
  disableDelete = false,
  extraClass,
  incrementCount,
  decrementCount,
  ...props
}) => {
  return (
    <div {...props} className="mt-md">
      <PartListItem
        articleNumber={part.articleNumber}
        name={part.name}
        thumbnail={getPartThumbnail(part)}
        hideDetailsButton
        showDeleteButton
        onDeleteButtonClick={onDeleteClick}
        counterProps={{
          count: part.quantity,
          incrementCount,
          decrementCount,
        }}
      />
    </div>
  );
};

export default AddedPartToWorkOrder;
