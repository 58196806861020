import * as React from "react";
const SvgLeftAlign = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 1.252,
  d: "M2.666 4.333h10.667m-10.667 4h6.667m-6.667 4h9.333"
}));
export default SvgLeftAlign;
