import { TEXT_ALIGN_TYPES } from "@shared/constants/index";
import React from "react";
import { useSlate } from "slate-react";

import EditorButton from "./EditorButton";
import { BLOCK_TYPES, isBlockActive, toggleBlock } from "../utils";

export const BLOCK_BUTTON_FORMAT = {
  NUMBERED_LIST: "numbered-list",
  BULLETED_LIST: "bulleted-list",
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
} as const;

export type BlockButtonFormatType =
  (typeof BLOCK_BUTTON_FORMAT)[keyof typeof BLOCK_BUTTON_FORMAT];

type BlockButtonPropsType = {
  format: BlockButtonFormatType;
  icon: React.JSX.Element;
  readOnly: boolean;
};

const BlockButton = ({ format, icon, readOnly }: BlockButtonPropsType) => {
  const editor = useSlate();

  return (
    <EditorButton
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format)
          ? BLOCK_TYPES.ALIGN
          : BLOCK_TYPES.TYPE,
      )}
      onMouseDown={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (readOnly) return;
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </EditorButton>
  );
};

export default BlockButton;
