import React from "react";
import { RenderElementProps } from "slate-react";

export const ELEMENT_TYPES = {
  BLOCK_QUOTE: "block-quote",
  BULLETED_LIST: "bulleted-list",
  NUMBERED_LIST: "numbered-list",
  HEADING_ONE: "heading-one",
  HEADING_TWO: "heading-two",
  LIST_ITEM: "list-item",
  PARAGRAPH: "paragraph",
} as const;

const ElementRenderer = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const style: React.CSSProperties = { textAlign: element.align };

  switch (element.type) {
    case ELEMENT_TYPES.BLOCK_QUOTE:
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );

    case ELEMENT_TYPES.BULLETED_LIST:
      return (
        <ul style={style} {...attributes} className="pl-lg mb-md text-xs">
          {children}
        </ul>
      );

    case ELEMENT_TYPES.HEADING_ONE:
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );

    case ELEMENT_TYPES.HEADING_TWO:
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );

    case ELEMENT_TYPES.LIST_ITEM:
      return (
        <li style={style} {...attributes} className="text-primary text-xs">
          {children}
        </li>
      );

    case ELEMENT_TYPES.NUMBERED_LIST:
      return (
        <ol style={style} {...attributes} className="pl-lg text-xs mb-md">
          {children}
        </ol>
      );

    default:
      return (
        <p
          style={style}
          {...attributes}
          className="m-0 text-primary text-xs no-styles"
        >
          {children}
        </p>
      );
  }
};

export default ElementRenderer;
