import Accordion, { ACCORDION_VARIANTS } from "@shared/ui/Accordion";
import AppHeader from "@shared/ui/AppHeader";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import TextEditor from "@shared/ui/TextEditor";
import moment from "moment-timezone";
import { DiamondsFour } from "phosphor-react";
import React, { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useRecoilState } from "recoil";

import Chat from "./_chat";
import TicketItemAssigneesList from "../components/ticketItemAssigneesList";
import { WORK_ORDER_BROADCAST_TOPICS } from "../constants/workOrders";
import { useGetClosedStatus } from "../hooks";
import useReorderWorkOrderList from "../hooks/_useReorderWorkOrderList";
import useWindowDimensions from "../hooks/_useWindowDimensions";
import { ExclamationIcon } from "../icons";
import { generateStatusBackgroundColor } from "../utils";
import { broadcaster } from "../utils/_appBroadcast";

import getEnums from "$/settings/enums";
import STATES from "$/settings/enums/procedure/states.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { UserAvatar } from "~/components/_avatar";
import { Drawer } from "~/components/_drawer";
import EmptySpace from "~/components/_emptySpace";
import useAuth from "~/components/general/_use-auth";
import { headerAtom } from "~/components/header/_inner";
import Hamburger from "~/components/header/Hamburger";
import PartsPreview from "~/components/parts/_partsPreview";
import SinglePart from "~/components/parts/_singlePart";
import ProcedureListItem from "~/components/ProcedureListItem";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";
import { SMALL_DEVICE_WIDTH } from "~/constants/global";
import { getTicketById } from "~/services";
import { trimText } from "~/utils/_initials";

const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const TABS = {
  CHAT: "chat",
  WORK_ORDER_OVERVIEW: "work-order-overview",
};

export default ({ intl, header }) => {
  const { user } = useAuth();
  const history = useHistory();
  const [headerOptions, setHeaderOptions] = useRecoilState(headerAtom);
  const { id } = useParams();
  const { ticket, loading } = getTicketById(id);
  const { width: deviceWidth } = useWindowDimensions();
  const { handleUpdateTicketStatus } = useReorderWorkOrderList();
  const [selectedPart, setSelectedPart] = React.useState({});
  const [showPreview, setShowPreview] = React.useState(false);

  const { closedStatus } = useGetClosedStatus();
  const statusItem = useMemo(
    () =>
      user?.oem?.statuses?.find((item) => ticket?.status === item._id) || {},
    [user?.oem?.statuses, ticket],
  );

  const isClosedWorkOrder = useMemo(
    () => ticket?.status === closedStatus?._id,
    [user?.oem?.statuses, ticket],
  );
  const handlePreviewClose = () => {
    setShowPreview(false);
    setSelectedPart(null);
  };

  const startAt = moment(ticket?.schedule?.startTime);
  const endAt = moment(ticket?.schedule?.endTime);
  const isScheduleSameDay = endAt.diff(startAt, "day") === 0;

  useEffect(() => {
    const goToWorkOrders = (ticketId, isNewTicket, isTicketDeleted, status) => {
      if (id === ticketId && status) handleUpdateTicketStatus({ id, status });
      if (isTicketDeleted && id === ticketId)
        history.push(`/${intl?.locale}/app/work-orders`);
    };

    const unsubscribeReOrderList = broadcaster.subscribe(
      WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
      goToWorkOrders,
    );
    return () => {
      unsubscribeReOrderList(
        WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
        goToWorkOrders,
      );
    };
  }, []);

  useEffect(() => {
    if (!loading && ticket?.title !== headerOptions.title) {
      setHeaderOptions({
        ...headerOptions,
        title:
          (ticket.ticketId ? `${ticket.ticketId} • ` : "") + ticket?.title ||
          "Machine ticket",
        lead:
          ticket?.machine?.name &&
          `${ticket?.machine?.name} - ${ticket?.machine?.serialNumber}`,
      });
    }
  }, [ticket]);

  const breadCrumbs = React.useMemo(
    () => [
      {
        label: isClosedWorkOrder
          ? intl?.messages?.header?.closedTicketListing?.title
          : intl?.messages?.header?.ticketListing?.title,
        link: header?.backUrl,
      },
      { label: ticket?.title },
    ],
    [ticket],
  );

  const finalizedProcedures = useMemo(
    () =>
      ticket?.procedures?.filter(
        (instance) => instance?.procedure?.state === STATES.FINALIZED,
      ) || [],
    [ticket],
  );

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="fw-single-page-wrapper u-flex u-flex-wrap">
        <div className="sticky top-0 z-[5] px-2xl bg-inverse flex items-center w-full">
          <AppHeader className="w-full" borderColor="border-transparent">
            <Breadcrumbs options={breadCrumbs} />
            {/* Hamburger component is only for mobile view */}
            <Hamburger />
          </AppHeader>
        </div>
        <div className="fw-page-content-wrapper u-flex u-flex-wrap u-width-100">
          <div className="fw-page-main-content u-width-100">
            <div className="ticket-status u-flex">
              <span
                className="status-bar"
                style={{
                  color: statusItem?.color,
                  backgroundColor: generateStatusBackgroundColor(
                    statusItem?.color,
                  ),
                }}
              >
                {statusItem?.label}
              </span>
            </div>
            <div className="ticket-title u-flex u-flex-wrap u-flex-column">
              <Headline size={HEADLINE_SIZES.SMALL} className="mb-sm md:mb-md">
                {trimText(ticket?.title, 75) || "Ticket"}
              </Headline>
              <div className="ticket-meta-info u-flex u-items-center u-text-color-gray-v2-60">
                <div className="u-inline-flex u-items-center u-text-color-blue-100">
                  {ticket?.machine?.name ? (
                    <Link
                      to={`/${intl?.locale}/app/assets/machines/${ticket?.machine?._id}`}
                      className="u-inline-flex u-items-center"
                    >
                      <DiamondsFour size={20} /> &nbsp;{" "}
                      <BodyText
                        size={BODY_TEXT_SIZES.X_SMALL}
                        color="text-brand"
                      >
                        {trimText(ticket?.machine?.name, 75)}
                      </BodyText>
                    </Link>
                  ) : (
                    <span className="single-ticket-machine-deleted">
                      <ExclamationIcon />{" "}
                      {intl?.messages?.tickets?.deletedMachine}
                    </span>
                  )}
                </div>
                {ticket?.machine?.serialNumber && (
                  <>
                    &nbsp; &#8226; &nbsp;
                    <BodyText
                      size={BODY_TEXT_SIZES.X_SMALL}
                      color="text-secondary"
                    >
                      {trimText(ticket?.machine?.serialNumber, 75)}
                    </BodyText>
                  </>
                )}
              </div>
            </div>
            <div className="page-details-tab">
              <UnderlineTabsWrapper
                currentTab={
                  deviceWidth <= SMALL_DEVICE_WIDTH
                    ? TABS.CHAT
                    : TABS.WORK_ORDER_OVERVIEW
                }
                tabs={[
                  deviceWidth <= SMALL_DEVICE_WIDTH && {
                    id: TABS.CHAT,
                    label: intl?.messages?.tickets?.workOrderTabs?.chat,
                    className: "mobile-element",
                    content: (
                      <Chat
                        type={isClosedWorkOrder ? "closed" : ""}
                        ticket={ticket}
                        intl={intl}
                      />
                    ),
                  },
                  {
                    id: TABS.WORK_ORDER_OVERVIEW,
                    label: intl?.messages?.tickets?.workOrderTabs?.overview,
                    content: (
                      <div className="ticket-overview-wrapper">
                        <div className="ticket-overview-top">
                          <div className="ticket-overview-top-left">
                            <div className="ticket-create-date">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="mb-2xs capitalize block max-lg:flex-1 max-lg:m-0 max-lg:truncate max-lg:pr-lg"
                              >
                                {intl?.messages?.tickets?.date}:
                              </BodyText>
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                className="max-lg:flex-1 max-lg:m-0 max-lg:truncate"
                              >
                                {ticket?.createdAt
                                  ? new Date(
                                      ticket?.createdAt,
                                    ).toLocaleDateString("it-IT")
                                  : null}
                              </BodyText>
                            </div>
                            <div className="ticket-id">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="mb-2xs capitalize block max-lg:flex-1 max-lg:m-0 max-lg:truncate max-lg:pr-lg"
                              >
                                {intl?.messages?.tickets?.ticketId}:
                              </BodyText>
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                className="max-lg:flex-1 max-lg:m-0 max-lg:truncate"
                              >
                                #{ticket?.ticketId}
                              </BodyText>
                            </div>
                            <div className={"ticket-schedule"}>
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="mb-2xs capitalize block max-lg:flex-1 max-lg:m-0 max-lg:truncate max-lg:pr-lg"
                              >
                                {intl?.messages?.tickets?.schedule}:
                              </BodyText>
                              <div className="value">
                                {!ticket?.schedule && "------"}
                                {ticket?.schedule &&
                                  ticket?.schedule?.isAllDay &&
                                  `${moment(ticket?.schedule?.startTime).format(
                                    "YYYY-MM-DD",
                                  )} - ${moment(
                                    ticket?.schedule?.endTime,
                                  ).format("YYYY-MM-DD")} (${
                                    intl?.messages?.tickets?.allDay
                                  })`}

                                {ticket?.schedule &&
                                  isScheduleSameDay &&
                                  !ticket?.schedule?.isAllDay &&
                                  `${moment(ticket?.schedule?.startTime).format(
                                    "YYYY-MM-DD",
                                  )} • ${moment(
                                    ticket?.schedule?.startTime,
                                  ).format("hh:mm A")} - ${moment(
                                    ticket?.schedule?.endTime,
                                  ).format("hh:mm A")}`}

                                {ticket?.schedule &&
                                  !isScheduleSameDay &&
                                  !ticket?.schedule?.isAllDay &&
                                  `${moment(ticket?.schedule?.startTime).format(
                                    "YYYY-MM-DD",
                                  )} • ${moment(
                                    ticket?.schedule?.startTime,
                                  ).format("hh:mm A")} - ${moment(
                                    ticket?.schedule?.endTime,
                                  ).format("YYYY-MM-DD")} • ${moment(
                                    ticket?.schedule?.endTime,
                                  ).format("hh:mm A")}`}
                              </div>
                            </div>
                            <div className="mobile-element u-flex u-items-center">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="mb-2xs capitalize block max-lg:flex-1 max-lg:m-0 max-lg:truncate max-lg:pr-lg"
                              >
                                {intl?.messages?.tickets?.reporter}:
                              </BodyText>
                              <UserAvatar
                                className={
                                  ticket?.user?.name
                                    ? "has-name"
                                    : "not-assigned"
                                }
                                name={
                                  ticket?.user?.name ||
                                  intl.messages?.common?.noAssignee
                                }
                                isDeleted={ticket?.user?.deleted}
                                variant="extra-small"
                                nameClassName="truncate max-w-[calc(100%-40px)]"
                              />
                            </div>
                            <div className="mobile-element u-flex u-items-center">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="mb-2xs capitalize block max-lg:flex-1 max-lg:m-0 max-lg:truncate max-lg:pr-lg"
                              >
                                {intl?.messages?.tickets?.oemAgent}:
                              </BodyText>
                              <div className="u-flex u-width-100">
                                <TicketItemAssigneesList
                                  assignees={ticket?.assignees}
                                  showUserIconOnUnassigned={false}
                                  singleAssigneeClassName="ticket-detail-single-assignee-user-avatar-container"
                                  nameClassName="break-words truncate max-w-[calc(100%-40px)]"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ticket-overview-middle u-flex desktop-element">
                          <div className="ticket-user-info u-inline-flex u-items-center">
                            <BodyText
                              size={BODY_TEXT_SIZES.X_SMALL}
                              color="text-secondary"
                              className="mr-sm"
                            >
                              {intl?.messages?.tickets?.reporter}:
                            </BodyText>
                            <UserAvatar
                              className={
                                ticket?.user?.name ? "has-name" : "not-assigned"
                              }
                              name={
                                ticket?.user?.name ||
                                intl.messages?.common?.noAssignee
                              }
                              variant="extra-small"
                              isDeleted={ticket?.user?.deleted}
                            />
                          </div>
                          <div className="ticket-user-info u-inline-flex u-items-center">
                            <BodyText
                              size={BODY_TEXT_SIZES.X_SMALL}
                              color="text-secondary"
                              className="mr-sm"
                            >
                              {intl?.messages?.tickets?.oemAgent}:
                            </BodyText>
                            <TicketItemAssigneesList
                              assignees={ticket?.assignees}
                              showUserIconOnUnassigned={false}
                              singleAssigneeClassName="ticket-detail-single-assignee-user-avatar-container"
                              nameClassName="break-words"
                            />
                          </div>
                        </div>
                        {user?.oem?.paidFeatures.includes(
                          paidFeaturesRef.procedures,
                        ) && (
                          <Accordion
                            variant={ACCORDION_VARIANTS.PROCEDURES}
                            title={intl?.messages?.tickets?.procedures}
                            defaultExpanded={true}
                            className="mb-md"
                          >
                            {finalizedProcedures.length > 0 ? (
                              <div className="procedures-container">
                                {finalizedProcedures.map((instance) => (
                                  <ProcedureListItem
                                    key={instance?.procedure?._id}
                                    item={instance?.procedure}
                                  />
                                ))}
                              </div>
                            ) : (
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                              >
                                {intl?.messages?.tickets?.noProcedures}
                              </BodyText>
                            )}
                          </Accordion>
                        )}
                        {ticket?.description && (
                          <Accordion
                            variant={ACCORDION_VARIANTS.DESCRIPTION}
                            title={intl?.messages?.tickets?.description}
                            className="max-lg:mb-md"
                          >
                            <TextEditor
                              keyId="ticket-description"
                              content={ticket?.description}
                              placeholder={" "}
                              readOnly
                            />
                          </Accordion>
                        )}
                        <EmptySpace />
                        <Accordion
                          variant={ACCORDION_VARIANTS.PARTS}
                          title={intl?.messages?.tickets?.parts}
                          defaultExpanded={true}
                        >
                          <div className="mk-accordion_scroll-content">
                            {ticket?.inventoryParts.map((part, index) => {
                              return (
                                <SinglePart
                                  key={index}
                                  part={{
                                    ...part.part,
                                    quantity: part.quantity,
                                  }}
                                  disableDelete={true}
                                  machine={ticket?.machine}
                                  onClick={() => {
                                    setSelectedPart(part.part);
                                    setShowPreview(true);
                                  }}
                                  extraClass="cursor-pointer"
                                />
                              );
                            })}
                          </div>
                        </Accordion>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="fw-page-chat desktop-element">
            <Chat
              type={isClosedWorkOrder ? "closed" : ""}
              ticket={ticket}
              intl={intl}
            />
          </div>
        </div>
      </div>
      <Drawer
        hideFooter
        isOpen={showPreview}
        onClose={handlePreviewClose}
        title={selectedPart?.name}
      >
        <PartsPreview data={selectedPart} machine={ticket?.machine} />
      </Drawer>
    </>
  );
};
