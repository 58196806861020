import { Minus, Plus } from "phosphor-react";
import React from "react";

export type CounterPropsType = {
  count: number;
  setCount?: (value: number) => void;
  incrementCount: () => void;
  decrementCount: () => void;
  isReadOnlyInput?: boolean;
};

const buttonClasses: string =
  "flex items-center justify-center w-3xl h-full p-0 text-primary bg-transparent border-y-0 border-solid border-primary cursor-pointer disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-tertiary disabled:opacity-40 hover:text-brand hover:border hover:border-brand";

const Counter = ({
  count,
  setCount = () => {},
  incrementCount,
  decrementCount,
  isReadOnlyInput = true,
}: CounterPropsType) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setCount(0);
      return;
    }
    setCount(parseInt(e.target.value));
  };

  const handleInputBlur = () => {
    if (!count) {
      setCount(0);
    }
  };

  return (
    <div className="w-auto h-3xl flex items-center bg-primary border border-solid border-primary rounded-lg">
      <button
        className={`${buttonClasses} border-r border-l-0 rounded-l-lg`}
        onClick={decrementCount}
        disabled={count === 0}
      >
        <Minus size={12} weight="bold" />
      </button>

      <input
        type="number"
        readOnly={isReadOnlyInput}
        className={`w-6xl h-full flex items-center px-sm py-0 font-manrope text-xs font-medium text-center bg-transparent border-none focus:outline-none ${
          count > 0 ? "text-brand" : "text-secondary"
        }`}
        value={
          count.toString().length > 1
            ? count.toString().replace(/^0+/, "")
            : count.toString()
        }
        min="0"
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />

      <button
        className={`${buttonClasses} border-r-0 border-l rounded-r-lg`}
        onClick={incrementCount}
      >
        <Plus size={12} weight="bold" />
      </button>
    </div>
  );
};

export default Counter;
